<template>
  <header-t></header-t>
  <div class=" nPage " style="display:">
    <div class="banner">
      <img src="@/assets/banner.jpg" style="widht:100%" />
    </div>
    <div class="nW indexTaskHall">
      <div class="pageTitle indexPtMar">
        <strong class="tit">任务大厅</strong>
        <span class="rset"
          ><el-button size="small" type="primary" @click="faburenwu"
            >发布任务</el-button
          ></span
        >
      </div>
      <div class="taskHallBox " style="">
        <div class="filter">
          <div class="f-sort">
            行业：<span
              :class="categoryAct === 'hy' ? 'act' : ''"
              @click="categoryFun('')"
            >
              不限 </span
            ><span
              v-for="(iam, iax) in category"
              :key="iax"
              :class="categoryAct === 'hy' + iam.fieldValue ? 'act' : ''"
              @click="categoryFun(iam.fieldValue)"
            >
              {{ iam.fieldLabel }}
            </span>
          </div>
          <div class="f-sort">
            人员：
            <span
              @click="acceptTypeFun('1')"
              :class="acceptTypeAct === 'fl1' ? 'act' : ''"
            >
              不限
            </span>
            <span
              @click="acceptTypeFun('2')"
              :class="acceptTypeAct === 'fl2' ? 'act' : ''"
              >企业</span
            >
            <span
              @click="acceptTypeFun('3')"
              :class="acceptTypeAct === 'fl3' ? 'act' : ''"
              >个人</span
            >
          </div>
        </div>
        <div class="Mt16">
          <task-li
            v-for="(ibm, ibx) in taskList"
            :key="ibx"
            :itm="ibm"
            @open="taskOpen"
            :category="category"
          ></task-li>
        </div>
        <div class="pagination">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="pages.total"
            @current-change="paginationPage"
          />
        </div>
      </div>
    </div>
  </div>
  <footer-s></footer-s>
</template>
<script setup>
import { ref } from 'vue'
import { sign, getItem } from '@/utils/storage'
import { useRouter } from 'vue-router'
import tools from '@/utils/tools.js'
import HeaderT from '@/components/Public/Header'
import FooterS from '@/components/Public/Footer'
import TaskLi from '@/components/Task/TaskLi'
import { getArticleList } from '@/api/task'
import { dictionary } from '@/api/sys'
import { ElMessageBox } from 'element-plus'
// import SvgIcon from '@/components/SvgIcon'
// const route = useRoute()
// console.log('home-- route = ---', route)
const fabuBtnShow = ref(false) // 发布按钮是否可见
const us = getItem('userinfo')
if (us !== null) {
  if (us.comSH === 2 || us.comSH === 0 || us.certification === 3) {
    fabuBtnShow.value = true
  }
}
// 发布任务
const faburenwu = () => {
  if (us === null) {
    ElMessageBox.alert('请先登录后再点击发布任务按钮', '用户未登录', {
      confirmButtonText: '确定',
      callback: action => {
        // goPage('/addTask')
      }
    })
  } else {
    if (us.certification === 0) {
      ElMessageBox.alert('请先完成实名认证', '操作受限', {
        confirmButtonText: '实名认证',
        callback: action => {
          goPage('/certification')
        }
      })
    } else if (us.certification === 1) {
      ElMessageBox.alert('请耐心等待管理员审核', '认证未审核', {
        confirmButtonText: '确定',
        callback: action => {}
      })
    } else if (us.certification === 2) {
      ElMessageBox.alert('实名认证审核未通过', '审核未通过', {
        confirmButtonText: '确定',
        callback: action => {
          goPage('/certification')
        }
      })
    } else {
      goPage('/addTask')
    }
  }
  // goPage('/addTask')
}

const categoryAct = ref('hy')
const category = ref([])
const pages = ref({ page: 1, size: 10, peopleType: '1', taskType: '' })
// 行业分类
const categoryFun = s => {
  categoryAct.value = 'hy' + s
  pages.value.taskType = s
  getList()
}
// 承接人员类型
const acceptTypeAct = ref('fl1')
const acceptTypeFun = s => {
  acceptTypeAct.value = 'fl' + s
  pages.value.peopleType = s
  getList()
}
// 列表查询条件
const taskList = ref([
  {
    id: 1,
    title: 'bbbb新闻标题新闻标题新闻标题新闻标题新闻标题新闻标题新闻标题111',
    budget: 9900.99,
    expectedStartTime: '2022-10-1', // 时间
    expectedEndTime: '2022-11-31', //
    hire: 4, // 已读人数
    hasRecruited: 4, // 分离
    category: 'bb', // 所属分
    acceptType: '', // 来源
    content: '新闻新闻描述内容描述内容描述内容描述内容描述内容描述内容描述内容'
  }
])
// 打开
const router = useRouter()
const taskOpen = ss => {
  router.push(`/task?d=${ss.ID}`)
}
const goPage = ss => {
  router.push(ss)
}
// 分页
const paginationPage = v => {
  pages.value.page = v
  getList()
}

// 获取分类
const taTypeFun = async () => {
  category.value = await dictionary(sign({ type: 'industry' }))
  getList()
}
taTypeFun()

// 获取列表
const getList = async () => {
  var parmas = tools.basic.filterEmptyData(
    JSON.parse(JSON.stringify(pages.value))
  )
  delete parmas.total
  if (parmas.peopleType === '1') {
    delete parmas.peopleType
  }
  var idt = await getArticleList(sign(parmas))
  taskList.value = idt.list
  pages.value.total = idt.total
}
</script>
<style scoped>
.pageTitle {
  display: flex;
  justify-content: space-between;
}
</style>
