<template>
  <div class="taskLi" @click="taskLiClik(itm)">
    <div class="leftPrice">
      <div class="budgetBox">
        <span class="budget">
          <strong>{{ itm.integral }}</strong>
        </span>
        <span class="budgetTag">任务预算</span>
      </div>
    </div>
    <div class="taskContent">
      <div class="tit">
        <strong>{{ itm.taskName }}</strong>
        <span v-if="itm.repair !== null" class="houbu">后补任务</span>
      </div>
      <div class="text">{{ itm.taskName }}</div>
      <div>
        <ul class="parameter">
          <li class="time">
            时间：<span>
              {{ itm.planStartDate }} 至 {{ itm.planEndDate }}
            </span>
          </li>
          <li>
            雇佣人数：<span>{{ itm.planPeopleNumber }} 人</span>
          </li>
          <li>
            所属行业：<span>{{ hangye(itm.taskType) }} </span>
          </li>
          <li>
            承接人员类型：<span
              >{{
                itm.peopleType === 1
                  ? '不限'
                  : itm.peopleType === 2
                  ? '企业'
                  : '个人'
              }}
            </span>
          </li>
          <!-- <li>
            评论人数：<span>{{ itm.hasRecruited }} </span>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'
// import { useRouter } from 'vue-router'
// import { useStore } from 'vuex'
const emits = defineEmits(['open'])
const props = defineProps({
  itm: {
    type: Object,
    required: { title: 'aaa' }
  },
  category: {
    type: Array,
    required: []
  }
})

const taskLiClik = its => {
  emits('open', its)
}
// 所属行业
const hangye = s => {
  var vt = ''
  props.category.forEach(est => {
    if (s + '' === est.fieldValue) {
      // return est.fieldLabel
      vt = est.fieldLabel
    }
  })
  return vt
}
</script>

<style lang="scss" scoped>
.taskContent .tit .houbu {
  color: #f00;
  border: solid 1px #d00;
  font-size: 10px;
  padding: 0px 4px;
  border-radius: 4px;
  display: inline-block;
  margin-left: 10px;
}
</style>
